export default class BrockmanLightbox {
  constructor() {
    this.selector = '[data-lightbox]:not([data-init])';
    this.allowedImageFormats = ['bmp', 'jpeg', 'jpg', 'jfif', 'gif', 'png', 'webp'];

    this.lightbox = null;
    this.image = null;
    this.keydownHandler = null;
    this.closeCallback = null;
  }

  run(container) {
    const targets = (container || document).querySelectorAll(this.selector);
    targets.forEach((target) => {
      target.dataset.init = true;

      const { lightbox } = target.dataset;
      if (lightbox === 'false') return;

      const a = target.closest('a');
      if (!a) return;

      const href = a.getAttribute('href');
      if (!this.isImage(href)) return;

      a.addEventListener('click', this.click.bind(this));
      a.dataset.lightbox = 'done';
    });
  }

  click(e) {
    const a = e.currentTarget.closest('a');
    this.renderImage(e, a);
  }

  open(closeCallback = null) {
    if (!this.isOpen) this.renderLightbox();

    this.keydownHandler = this.keydown.bind(this);
    document.addEventListener('keydown', this.keydownHandler);
    document.body.dataset.lightboxOpen = true;
    this.isOpen = true;

    this.closeCallback = closeCallback;
  }

  close() {
    if (!this.isOpen) return;

    this.lightbox.remove();
    document.removeEventListener('keydown', this.keydownHandler);
    document.body.dataset.lightboxOpen = false;
    this.isOpen = false;

    if (this.closeCallback) this.closeCallback();
    this.closeCallback = null;
  }

  renderImage(e, target) {
    const href = target.getAttribute('href');
    e.preventDefault();
    e.stopPropagation();
    this.open();
    this.image = document.createElement('img');
    this.lightbox.appendChild(this.image);
    this.image.addEventListener('load', this.imageLoaded.bind(this));
    this.image.src = href;
  }

  imageLoaded() {
    const spinner = this.lightbox.querySelector('.spinner');
    if (spinner) spinner.remove();
  }

  renderLightbox() {
    this.lightbox = document.createElement('div');
    this.lightbox.innerHTML = `<span class="spinner large"></span>`;
    this.lightbox.classList.add('lightbox_fullscreen');
    this.lightbox.addEventListener('click', this.close.bind(this));
    document.body.appendChild(this.lightbox);
  }

  keydown(e) {
    switch (e.keyCode) {
      case 27: // escape
        e.preventDefault();
        this.close();
        break;

      default:
        break;
    }
  }

  isImage(uri) {
    const formats = this.allowedImageFormats.join('|');
    const re = new RegExp(`\\.(${formats})\\/?($|\\?)`, 'i');
    return uri.match(re) !== null;
  }
}
