export default class BrockmanAutoTimeZone {
  /*
   * Converts all <time> HTML elements (by default) to the user's local time
   * zone. Requires the elements to have `datetime` attribute set to a valid
   * datetime, and `data-auto-time-zone` attribute to be set to `true`. If the
   * `datetime` attribute has no time zone information, it's assumed to be UTC.
   *
   * The contents of each <time> element is replaced with the local version of
   * the time. The format of thiss can be specified per <time> element by using
   * the following data attributes: `year`, `month`, `day`, `weekday`, `hour`,
   * `minute`, and `second`. By default, it uses `hour` and `minute` (both set
   * to `numeric`).
   *
   * Valid values from these attributes can be found in the Internationalization
   * API Specification: https://tc39.es/ecma402/#datetimeformat-objects.
   *
   * A data attribute `local-date` is also updated, which allows you to see if
   * the date has changed as a result of the update to local time.
   */

  constructor(args) {
    // By default, select all <time> elements
    this.selector = args.selector ? args.selector : 'time';

    // Publication language for relative time
    this.language = args.language !== undefined ? args.language : 'en';

    // If this is true, use 24h time
    this.militaryTime = !!args.militaryTime;

    // If this is true, also return a span containing the abbreviated time zone
    this.showTimeZone = args.showTimeZone !== undefined ? args.showTimeZone : true;

    this.loc = args.loc ? args.loc : {
      justNow: 'Just now',
    };

    // Set up an event which triggers when a time update completes
    this.event = new Event('BrockmanAutoTimeZone');
  }

  static getOptions(element) {
    const { year, month, day, weekday, hour, minute, second } = element.dataset;

    let options = {
      year,
      month,
      day,
      weekday,
      hour,
      minute,
      second,
    };

    // Remove undefined options
    Object.keys(options).forEach((key) => options[key] == null && delete options[key]);

    // Set defaults if there aren't any options set
    if (!Object.keys(options).length) {
      options = {
        hour: 'numeric',
        minute: 'numeric',
      };
    }

    // Add default options
    options.hour12 = !this.militaryTime;

    return options;
  }

  static getTimeZone(datetime) {
    const dtFormat = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      timeZoneName: 'short',
    });
    const dt = new Date(datetime);
    const str = dtFormat.format(dt);
    return str.split(' ')[1];
  }

  static getLocalDateTime(datetime, options) {
    const dtFormat = new Intl.DateTimeFormat('default', options);
    const dt = new Date(datetime);
    return dtFormat.format(dt);
  }

  static getLocalDate(datetime) {
    const year = new Intl.DateTimeFormat('default', { year: 'numeric' });
    const month = new Intl.DateTimeFormat('default', { month: 'numeric' });
    const day = new Intl.DateTimeFormat('default', { day: 'numeric' });
    const dt = new Date(datetime);
    return `${year.format(dt)}-${month.format(dt).padStart(2, '0')}-${day.format(dt).padStart(2, '0')}`;
  }

  getRelativeDate(element, datetime) {
    // Display date in relative time for max of 7 days from today
    const current = new Date();
    const month = new Intl.DateTimeFormat('default', { month: 'long' });
    const day = new Intl.DateTimeFormat('default', { day: 'numeric' });

    // Default datetime format with time, day, and month (e.g. 12:00 pm, 30 December)
    const dt = new Date(datetime);
    const formattedDate = day.format(dt);
    const defaultDate = `${formattedDate} ${month.format(dt)}`;
    let defaultStr = BrockmanAutoTimeZone.getTimeDisplay(element, datetime);
    if (this.showTimeZone) {
      defaultStr += ` <span class="time_zone">${BrockmanAutoTimeZone.getTimeZone(datetime)}</span>`;
    }
    defaultStr += this.dateString(defaultDate);

    const elapsed = current - dt;
    return this.generateRelativeDate(elapsed, defaultStr);
  }

  generateRelativeDate(elapsed, defaultStr) {
    const rtf1 = new Intl.RelativeTimeFormat(this.language, { numeric: 'auto' });
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const tomorrow = day * 2;
    const week = day * 7;

    switch(true) {
      case elapsed < minute:
        return this.dateString(this.loc.justNow);
      case elapsed < hour:
        return this.dateString(rtf1.format(-Math.round(elapsed/minute), 'minute'));
      case elapsed < day:
        return this.dateString(rtf1.format(-Math.round(elapsed/hour), 'hour'));
      case elapsed < tomorrow:
        let dayFormat = rtf1.format(-Math.round(elapsed/day), 'day');
        let yesterday = dayFormat.charAt(0).toUpperCase() + dayFormat.slice(1);
        return this.dateString(yesterday);
      case elapsed < week:
        return this.dateString(rtf1.format(-Math.round(elapsed/day), 'day'));
      default:
        return defaultStr;
    }
  }

  dateString(date) {
    return `<span class="date">${date}</span>`;
  }

  static fixNoonAndMidnight(str) {
    // `12:XX am/pm` is currently incorrectly returned as `0:XX am/pm` by the
    // Internationalization API for some locales so we have to fix it ourselves.
    return str.replace(/^0:(\d\d) (am|pm)/, '12:$1 $2');
  }

  static getTimeDisplay(element, datetime) {
    const options = BrockmanAutoTimeZone.getOptions(element);
    let time = BrockmanAutoTimeZone.getLocalDateTime(datetime, options);
    return `<span class="time">${BrockmanAutoTimeZone.fixNoonAndMidnight(time)}</span>`;
  }

  run(container) {
    const targets = (container || document).querySelectorAll(`${this.selector}[datetime]`);
    targets.forEach((element) => {
      this.updateElementDateTimeToLocal(element);
    });
    window.dispatchEvent(this.event);
    if (this.onUpdatedCallback) this.onUpdatedCallback();
  }

  updateElementDateTimeToLocal(el) {
    const element = el;
    const { autoTimeZone, autoRelativeDate } = element.dataset;
    const datetime = element.getAttribute('datetime');
    let str;

    if (autoRelativeDate) {
      str = this.getRelativeDate(element, datetime);
    } else if (!autoTimeZone) return;
    else {
      str = BrockmanAutoTimeZone.getTimeDisplay(element, datetime);
      if (this.showTimeZone) {
        str += ` <span class="time_zone">${BrockmanAutoTimeZone.getTimeZone(datetime)}</span>`;
      }
    }

    element.innerHTML = str;
    element.dataset.localDate = BrockmanAutoTimeZone.getLocalDate(datetime);
  }
}
